import React from "react";
import Alert from "./Alert";
import IsLoading from "./IsLoading";
import { useHistory } from "react-router-dom";

export const ModalsListener = () => {
  // "normal" history doesn't work
  let history = useHistory();
  return (
    <React.Fragment>
      <Alert></Alert>
      <IsLoading></IsLoading>
    </React.Fragment>
  );
};

export default ModalsListener;
