import { ADD_FILTER, UPDATE_AREA_FILTER, REMOVE_FILTER } from "../actions/types";

const initialState = {
  geometry: null,
  filters: [
    {
      id: 1,
      params: ["VAVH"],
      datasets: ["WAVE_GLO_PHY_SWH_L3_NRT_014_001_ORBITS"],
      platform: ["CFOSAT"],
      start_time: "2021-01-01 00:00:00",
      end_time: "2021-01-01 23:59:59",
    }
  ]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, { ...action.payload, id: state.filters.length + 1 }]
      }
    case REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter(f => f.id !== action.payload)
      }
    case UPDATE_AREA_FILTER:
      return {
        ...state,
        geometry: action.payload
      }
    default:
      return state;
  }
}
