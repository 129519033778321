export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_SIDEBAR_WITHOUT_CLICK = "OPEN_SIDEBAR_WITHOUT_CLICK";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const HIDE_SIDEBAR = "HIDE_SIDEBAR";
export const UPDATE_LAYOUT = "UPDATE_LAYOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const IS_LOADING = "IS_LOADING";
export const ADD_FILTER = "ADD_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const UPDATE_AREA_FILTER = "UPDATE_AREA_FILTER";

// export const API_URL = "http://mespac.gdpanalytics.local:8000/api";
// export const GEOSERVER_URL = "http://130.192.238.37:8080/geoserver";
export const API_URL = "https://demo.mespac-space.com/api";
export const GEOSERVER_URL = "https://demo.mespac-space.com/geoserver";
