import { combineReducers } from "redux";
import auth from "./auth";
import layout from "./layout";
import errors from "./errors";
import isLoading from "./isLoading";
import filters from "./filters";

export default combineReducers({
  auth,
  layout,
  errors,
  isLoading,
  filters,
});
