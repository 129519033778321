import axios from "axios";
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT_SUCCESS,
  LOGIN_FAIL,
  API_URL,
  UPDATE_LAYOUT,
  IS_LOADING,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";
import { menusPrimarySidebar } from "../utils/menusPrimarySidebar";
import { menusSecondarySidebar } from "../utils/menusSecondarySidebar";
import { getErrors } from "../utils/getErrors";
import SuccessAlert from "../components/Modals/SuccessAlert";

// CHECK TOKEN & LOAD USER
export const loadUser =
  (create_sidebar_menu = false) =>
  async (dispatch) => {
    try {
      // check if the token is valid and also user info
      const res = await axios.get(
        `${API_URL}/autenticazione/user/`,
        tokenConfig()
      );
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      if (create_sidebar_menu === true) {
        // based on the user create the menus in the sidebar
        dispatch({
          type: UPDATE_LAYOUT,
          payload: { key: "menus_primary", value: menusPrimarySidebar },
        });
        dispatch({
          type: UPDATE_LAYOUT,
          payload: { key: "menus_secondary", value: menusSecondarySidebar },
        });
      }
    } catch {
      dispatch({
        type: AUTH_ERROR,
      });
    }
  };

export const login = (username, password) => async (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Request Body
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(
      `${API_URL}/autenticazione/login/`,
      body,
      config
    );
    localStorage.setItem("token", res.data.token);
    dispatch(loadUser(true));
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    getErrors(err, dispatch);
  }
};

// LOGOUT
export const logout =
  (reload = true) =>
  async (dispatch) => {
    try {
      await axios.post(
        `${API_URL}/autenticazione/logout/`,
        null,
        tokenConfig()
      );
      dispatch({
        type: LOGOUT_SUCCESS,
      });
      if (reload === true) {
        window.location.reload();
      }
    } catch (err) {
      getErrors(err, dispatch);
    }
  };

// UPDATE INFO ACCOUNT
export const updateInfoAccount = (form) => async (dispatch) => {
  try {
    dispatch({
      type: IS_LOADING,
    });
    const res = await axios({
      method: "put",
      url: `${API_URL}/autenticazione/user/`,
      data: form,
      ...tokenConfig(),
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
    dispatch({
      type: IS_LOADING,
    });
    SuccessAlert("Done!");
  } catch (err) {
    dispatch({
      type: IS_LOADING,
    });
    getErrors(err, dispatch);
  }
};
