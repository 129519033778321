import React, { useEffect, lazy } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loadUser } from "./Dashboard/actions/auth";
import PrivateRoute from "./Dashboard/layout/PrivateRoute";
import ModalsListener from "./Dashboard/components/ModalsListener/ModalsListener";
import ScrollToTop from "./Dashboard/components/ScrollToTop/ScrollToTop";
import Loading from "./Dashboard/layout/Loading";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "./App.css";

// create history
const hist = createBrowserHistory();

// lazy load all routes in the application
const Login = lazy(() => import("./Website/routes/Login/Login"));

const Dashboard = lazy(() => import("./Dashboard/layout"));

export const App = () => {
  useEffect(() => {
    store.dispatch(loadUser(true));
    // document.title has been moved in index.js in layout
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
      <Provider store={store}>
        <Router history={hist}>
          <div className="App">
            <ModalsListener></ModalsListener>
            <ScrollToTop />
            <Switch>
              <React.Suspense fallback={<Loading></Loading>}>
                <Route exact path="/" component={Login}></Route>
                <PrivateRoute
                  path="/dashboard"
                  component={Dashboard}
                ></PrivateRoute>
              </React.Suspense>
            </Switch>
          </div>
        </Router>
      </Provider>
    </LocalizationProvider >
  );
};

export default App;
