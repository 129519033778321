import { IS_LOADING } from "../actions/types";

const initialState = {
  is_loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        is_loading: !state.is_loading,
      };
    default:
      return state;
  }
}
