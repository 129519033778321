import { GET_ERRORS } from "../actions/types";

export const getErrors = (err, dispatch) => {
  var errors = {
    msg: { non_field_errors: "There has been an error" },
    status: 400,
  };
  if (err.response !== undefined) {
    errors = {
      msg: err.response.data,
      status: err.response.status,
    };
  }
  dispatch({
    type: GET_ERRORS,
    payload: errors,
  });
};
